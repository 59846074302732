import React, {useEffect, useState} from 'react'
import {IntlProvider} from 'react-intl'
import {messages} from '../i18n/messages'
import AuthInit from './modules/auth/redux/AuthInit'
import {LanguageContext} from '../AppContext'
import Routes from './routes/Routes'
import {GetLanguage, SetLanguage} from './modules/global/helpers/LocalStoragHelper'
import {PageDataProvider} from './layouts/core/PageData'
import MainInit from './layouts/MainInit'

function App() {
  const [lang, setLang] = useState<'uz'|'ru'|'en'>(()=>GetLanguage())

  const changeLang = () => {
    SetLanguage(lang)
    document.querySelector('html')?.setAttribute('lang', lang)
  }

  useEffect(changeLang, [lang])

  return (
    <>
      <PageDataProvider>
        <IntlProvider locale={lang} messages={messages[lang]}>
          <LanguageContext.Provider value={{lang, setLang}}>
            <AuthInit>
              <Routes/>
            </AuthInit>
          </LanguageContext.Provider>
        </IntlProvider>
        <MainInit/>
      </PageDataProvider>
    </>
  )
}

export default App
