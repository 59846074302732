import React, {useEffect, useState} from 'react'
import './PostInfoWithLine.css'
import {NumberFormat} from '../helpers/ToHumansString'
import {postLike} from '../redux/postRatingCRUD'

type Props = {
  id: string
  views: number
  likes: number
}
const TitleWithLine: React.FC<Props> = ({id, views, likes}) => {

  const [viewsValue, setViewsValue] = useState<number>(0)
  const [likesValue, setLikesValue] = useState<number>(0)
  const [likeAllow, setLikeAllow] = useState<boolean>(true)

  const addLikeHandler = () => {
    if (likeAllow && id && id.length === 36) {
      postLike(id).then(r=>{
        if (r.status === 200) {
          setLikesValue(r.data.likes)
        }
        setLikeAllow(false)
        setTimeout(()=>setLikeAllow(true), 24*60*60*1000)
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>setViewsValue(views), [views])

  // eslint-disable-next-line
  useEffect(()=>setLikesValue(likes), [likes])

  return <div className='post-info-with-line'>
    {viewsValue > 0 ? <>
      <span>👁 {NumberFormat(viewsValue)}</span>
    </> : null}
    {viewsValue > 0 ? <>
      <span onClick={addLikeHandler}>👍 {NumberFormat(likesValue)}</span>
    </> : null}
  </div>
}
export default TitleWithLine