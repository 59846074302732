import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './app/App'
import {Provider} from 'react-redux'
import store, {persistor} from './setup/redux/Store'
import {PersistGate} from 'redux-persist/integration/react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
      <App />
    </PersistGate>
  </Provider>
)
