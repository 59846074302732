import React from 'react'
import './TitleWithLine.css'

type Props = {
  title: string
}
const TitleWithLine: React.FC<Props> = ({title}) => {

  return <div className='title-with-line'>
    <div className='line'></div><span>{title}</span><div className='line'></div>
  </div>
}
export default TitleWithLine