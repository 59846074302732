export type SeoModel = {
  keys: string
  desc: string
  canonical: string
  index: boolean
  follow: boolean
}

export const InitialSeo: SeoModel = {
  keys: '',
  desc: '',
  canonical: '',
  index: false,
  follow: false,
}