/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import './RecPostList.css'
import TitleWithLine from '../../global/components/TitleWithLine'
import {useIntl} from 'react-intl'
import {postRecommendedList} from '../redux/postCRUD'
import ImageView from '../../global/components/ImageView'
import {Truncate} from '../../global/helpers/StringHelper'
import {Link} from 'react-router-dom'
import {IsMobile} from '../../global/helpers/PageHelpers'

type PostModel = {
  id: string
  imageId: string
  title: string
  slug: string
  views: number
  likes: number
}

type Props = {
  postId?: string
}

const RecPostList: React.FC<Props> = ({postId}) => {
  const intl = useIntl()

  const [list, setList] = useState<PostModel[]>([])

  const loadData = () => {
    postRecommendedList(intl.locale, postId).then(r=>{
      if (r.status === 200) {
        const list = (r.data ?? []).map((e: any)=>{
          return {
            id: e.id,
            imageId: e.image_id,
            title: e.title ?? '',
            slug: e.slug ?? '',
            views: e.views,
            likes: e.likes,
          }
        })
        setList(list)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=> loadData(), [intl.locale])

  return (<>
    <TitleWithLine title={intl.formatMessage({id: 'Recommended posts'})}/>
    <div className="rec-post-list-box">
      {list.map(e=><div key={e.id} className='rec-post-list-item'>
        <Link to={`/${intl.locale}/post/${e.slug}-${e.id}`}><ImageView id={e.imageId} screen={IsMobile() ? 'small' : 'mobile'} /></Link>
        <Link to={`/${intl.locale}/post/${e.slug}-${e.id}`} className='rec-post-title'>{Truncate(e.title, 50)}</Link>
      </div>)}
    </div>
  </>
  )
}
export default RecPostList