import React from 'react'
import {useParams} from 'react-router-dom'
import PostView from './components/PostView'

type Props = {
  tid: string
}
const PostViewPage: React.FC = () => {
  const {tid} = useParams<Props>()

  return <PostView id={tid ? tid.slice(tid.length-36) : ''}/>
}
export default PostViewPage