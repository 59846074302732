import React, {createContext, useContext, useEffect, useState} from 'react'
import {InitialSeo, SeoModel} from '../models/SeoModel'

export interface PageLink {
  title: string
  path: string
  isActive: boolean
  isSeparator?: boolean
}

export interface Notify {
  title: string
  message: string
  danger: boolean
}

export const InitialNotify: Notify = {
  title: '',
  message: '',
  danger: false,
}

export interface PageDataContextModel {
  pageTitle?: string
  setPageTitle(v: string): void,
  seo?: SeoModel
  setSeo(v: SeoModel): void,
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  showLoading: boolean
  setShowLoading: (_show: boolean) => void
  notifyPush: Notify
  setNotifyPush: (_show: Notify) => void
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (v: string) => {},
  setSeo(v: SeoModel) {},
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
  setPageDescription: (_description: string) => {},
  showLoading: false,
  setShowLoading: (_show: boolean) => {},
  notifyPush: InitialNotify,
  setNotifyPush: (_show: Notify) => {},
})

const PageDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [seo, setSeo] = useState<SeoModel>(InitialSeo)
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [showLoading, setShowLoading] = useState(false)
  const [notifyPush, setNotifyPush] = useState(InitialNotify)

  const loadTitleData = () => {
    document.title = `${pageTitle ?? ''} | OFEN.uz`
  }
  
  const loadSeoData = () => {
    // keywords
    if (seo.keys.length > 0) {
      const metaKeys = document.createElement('meta')
      metaKeys.setAttribute('name', 'keywords')
      metaKeys.setAttribute('content', seo.keys)
      document.head.appendChild(metaKeys)
    }
    // description
    if (seo.desc.length > 0) {
      const metaDesc = document.createElement('meta')
      metaDesc.setAttribute('name', 'description')
      metaDesc.setAttribute('content', seo.desc)
      document.head.appendChild(metaDesc)
    }
    // canonical
    if (seo.canonical.length > 0) {
      const metaCanonical = document.createElement('meta')
      metaCanonical.setAttribute('name', 'canonical')
      metaCanonical.setAttribute('content', seo.canonical)
      document.head.appendChild(metaCanonical)
    }
    // og site name
    const metaOgSiteName = document.createElement('meta')
    metaOgSiteName.setAttribute('name', 'og:site_name')
    metaOgSiteName.setAttribute('content', 'OFEN uz')
    document.head.appendChild(metaOgSiteName)
    // og description
    if (seo.desc.length > 0) {
      const metaOgDescription = document.createElement('meta')
      metaOgDescription.setAttribute('name', 'og:description')
      metaOgDescription.setAttribute('content', seo.desc)
      document.head.appendChild(metaOgDescription)
    }
    // og title
    const metaOgTitle = document.createElement('meta')
    metaOgTitle.setAttribute('name', 'og:title')
    metaOgTitle.setAttribute('content', document.title)
    document.head.appendChild(metaOgTitle)
    // og title
    const metaOgUrl = document.createElement('meta')
    metaOgUrl.setAttribute('name', 'og:url')
    metaOgUrl.setAttribute('content', document.location.href)
    document.head.appendChild(metaOgUrl)
    // robots
    const robotsContent = []
    if (seo.index) {
      robotsContent.push('index')
    }
    if (seo.follow) {
      robotsContent.push('follow')
    }
    if (robotsContent.length > 0) {
      const metaRobots = document.createElement('meta')
      metaRobots.setAttribute('name', 'robots')
      metaRobots.setAttribute('content', robotsContent.join(','))
      document.head.appendChild(metaRobots)
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadTitleData(), [pageTitle])

  // eslint-disable-next-line
  useEffect(()=>loadSeoData(), [seo])

  const value: PageDataContextModel = {
    pageTitle,
    seo,
    setSeo,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    showLoading,
    setShowLoading,
    notifyPush,
    setNotifyPush,
  }
  return (
    <PageDataContext.Provider value={value}>
      {children}
    </PageDataContext.Provider>
  )
}

function usePageData() {
  return useContext(PageDataContext)
}

export { PageDataProvider, usePageData }
