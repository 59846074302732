export const ScrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.requestAnimationFrame(ScrollToTop)
    window.scrollTo(0, c - c / 8)
  }
}

export const ScrollToTopPost = () => {
  const content = document.querySelector('.top-nav')
  if (content) {
    window.scrollTo(0, content.getBoundingClientRect().height - 30)
  }
}

export const ElWidth = (selector: string): number => {
  const el = document.querySelector(selector)
  return el ? el.getBoundingClientRect().width : 0
}

export const IsMobile = () => ElWidth('#root') <= 412

export const ContentWidth = (): number => {
  return document.body.offsetWidth
}

export const ImageHeight = (width: number): number => {
  return (width / 16) * 9
}
