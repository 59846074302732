const _STORE_KEY = process.env.REACT_APP_STORE_KEY ?? 'app-store-key'
export const GetLocalStorage = (key: string) => {
  let result = ''
  if (key.length > 0) {
    const data = JSON.parse(localStorage.getItem(_STORE_KEY) || '{}')
    if (Object.keys(data).length > 0) {
      try {
        result = data[key]
      } catch (e) {
        console.log('parse store data error', e)
      }
    }
  }
  return result
}

export const SetLocalStorage = (data: object) => {
  if (Object.keys(data).length > 0) {
    localStorage.setItem(_STORE_KEY, JSON.stringify(data))
  }
}

export const GetLanguage = (): 'uz' | 'ru' | 'en' => {
  switch (GetLocalStorage('lang')) {
    case 'ru':
      return 'ru'
    case 'en':
      return 'en'
    default:
      return 'uz'
  }
}

export const SetLanguage = (lang: 'uz' | 'ru' | 'en') => {
  SetLocalStorage({ lang })
}
