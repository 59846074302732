import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {pageGet} from './redux/pageCRUD'
import './PageView.css'
import {usePageData} from '../layouts/core/PageData'

type PageModel = {
  id: string
  title: string
  content: string
}

export const InitPage: PageModel = {
  id: '',
  title: '',
  content: '',
}

type Props = {
  code: string
}

const PageView: React.FC<Props> = ({code}) => {
  const intl = useIntl()
  const{setPageTitle} = usePageData()
  
  const [page, setPage] = useState<PageModel>(InitPage)
  
  const loadData = () => {
    if (code.length > 0) {
      pageGet(code, intl.locale).then(r=>{
        if (r.status === 200) {
          setPage({
            id: r.data.id,
            title: r.data.title,
            content: r.data.content,
          })
          setPageTitle(r.data.title)
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [code, intl.locale])

  return <div className='page-view-box'>
    <h1>{page.title}</h1>
    <div dangerouslySetInnerHTML={{__html: page.content}}/>
  </div>
}
export default PageView