import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import PostList from './components/PostList'
import {usePageData} from '../../layouts/core/PageData'
import {useIntl} from 'react-intl'

type Props = {
  tid: string
}
const PostViewPage: React.FC = () => {
  const intl = useIntl()
  const {tid} = useParams<Props>()
  const{setPageTitle} = usePageData()

  // eslint-disable-next-line
  useEffect(()=>setPageTitle(intl.formatMessage({id: 'Posts'})), [intl.locale])

  return <PostList id={tid ? tid.slice(tid.length-36) : ''}/>
}
export default PostViewPage