'use server'
import React, {useEffect, useState} from 'react'
import './PostView.css'
import {useIntl} from 'react-intl'
import {postGet, postSimilarList} from '../redux/postCRUD'
import ImageView from '../../global/components/ImageView'
import {toAbsoluteUrl, toImageUrl} from '../../global/helpers/AssetHelper'
import {imageThumb} from '../../global/redux/imageCRUD'
import PostInfoWithLine from '../../global/components/PostInfoWithLine'
import RecPostList from './RecPostList'
import {usePageData} from '../../../layouts/core/PageData'
import {InitialSeo, SeoModel} from '../../../layouts/models/SeoModel'
import {IsMobile} from '../../global/helpers/PageHelpers'

type Props = {
  id: string
}

type PostModel = {
  id: string
  imageId: string
  title: string
  content: string
  slug: string
  views: number
  likes: number
  seo: SeoModel
}

export const InitPost: PostModel = {
  id: '',
  imageId: '',
  title: '',
  content: '',
  slug: '',
  views: 0,
  likes: 0,
  seo: InitialSeo,
}

const PostView: React.FC<Props> = ({id}) => {
  const intl = useIntl()
  const {setSeo, setPageTitle} = usePageData()

  const [contents, setContents] = useState<string[]>([])
  const [post, setPost] = useState<PostModel>(InitPost)

  const parseSimilarPost = (m: PostModel) => {
    if (m.content.length > 36) {
      const regex = /\[[A-Z0-9-]+(?:,[A-Z0-9-]+)*\]/
      const match = m.content.match(regex)

      if (match && match.length > 0) {
        let content = ''
        const similar: {index: number, ids: string}[] = []
        for (const sp of match) {
          similar.push({index: m.content.indexOf(sp), ids: sp})
          content += m.content.replace(sp, '')
        }
        for (const sm of similar) {
          const ids = sm.ids.match(/(?:[A-Z0-9-]+)/g)
          if (ids && ids.length > 0) {
            postSimilarList(intl.locale, ids).then(r=>{
              for (const s of (r.data ?? [])) {
                imageThumb(s.image_id, 'small').then(r=>{
                  const tc = [...contents, content.slice(0, sm.index)]
                  if (r.status === 200 && r.data.path.length > 36) {
                    const sml = `<a class="post-view-similar-box" href="${toAbsoluteUrl(`/${intl.locale}/post/${s.id}`)}">
                    <img src="${toImageUrl(r.data.path)}" alt="${r.data.title}"/>
                    <div class="post-view-similar-info-box">
                      <span class="post-view-similar-caption">${intl.formatMessage({id: 'Similar post'})}:</span>
                      <span>${s.title}</span>
                    </div>
                  </a>`

                    tc.push(sml)
                  }
                  tc.push(content.slice(sm.index))
                  setContents(tc)
                })
              }
            })
          }
        }
      } else {
        setContents([m.content])
      }
    } else {
      setContents([m.content])
    }
  }

  const loadData = () => {
    postGet(id, intl.locale).then(r=>{
      if (r.status === 200) {
        const m: PostModel = {
          id: r.data.id,
          imageId: r.data.image_id,
          title: r.data.title,
          content: r.data.content ?? '',
          slug: r.data.slug,
          views: r.data.views,
          likes: r.data.likes,
          seo: {
            keys: r.data.meta_keys ?? '',
            desc: r.data.meta_desc ?? '',
            canonical: r.data.canonical ?? '',
            index: r.data.robot_index === 'true',
            follow: r.data.robot_follow === 'true',
          }
        }
        setPost(m)
        parseSimilarPost(m)
        setPageTitle(m.title)
        setSeo(m.seo)
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [id, intl.locale])

  return <div className='post-view-box'>
    <ImageView screen={IsMobile() ? 'mobile' : 'desktop'} id={post.imageId}/>
    <span className='post-view-title'>{post.title}</span>
    <PostInfoWithLine id={post.id} views={post.views} likes={post.likes}/>

    {contents.map((e, i)=><div
      key={i}
      dangerouslySetInnerHTML={{__html: e}}
      className='post-view-content'
    />)}

    <RecPostList />
  </div>
}
export default PostView