/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import './PostList.css'
import TitleWithLine from '../../global/components/TitleWithLine'
import {useIntl} from 'react-intl'
import {postList} from '../redux/postCRUD'
import ImageView from '../../global/components/ImageView'
import {Truncate} from '../../global/helpers/StringHelper'
import {Link, useNavigate} from 'react-router-dom'
import {IsMobile} from '../../global/helpers/PageHelpers'
import {IDataFilter} from '../../global/models/DataFilter'

type PostModel = {
  id: string
  imageId: string
  title: string
  description: string
  slug: string
  views: number
  likes: number
}


export const InitialPost: PostModel = {
  id: '',
  imageId: '',
  title: '',
  description: '',
  slug: '',
  views: 0,
  likes: 0,
}

type Props = {
  id?: string
}

const PostList: React.FC<Props> = ({id}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const isMobile = IsMobile()

  const [list, setList] = useState<PostModel[]>([
    {...InitialPost, id: '1'},
    {...InitialPost, id: '2'},
    {...InitialPost, id: '3'},
    {...InitialPost, id: '4'},
    {...InitialPost, id: '5'},
    {...InitialPost, id: '6'},
    {...InitialPost, id: '7'},
    {...InitialPost, id: '8'},
  ])
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)

  const loadData = () => {
    const filter: IDataFilter = {lan: intl.locale, perPage: isMobile ? 4 : 8, page}
    if (id && id.length === 36) {
      filter.categories = [id]
    }

    postList(filter).then(r=>{
      if (r.status === 200) {
        const items = (r.data.items ?? []).map((e: any)=>{
          return {
            id: e.id,
            imageId: e.image_id,
            title: e.title ?? '',
            description: e.description ?? '',
            slug: e.slug ?? '',
            views: e.views,
            likes: e.likes,
          }
        })
        const page = Number(r.data.paginate.page)
        setList([...(page > 1 ? list : []), ...items])
        setPage(page)
        setHasNextPage(page < Number(r.data.paginate.total_pages))
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=> loadData(), [page, intl.locale])

  return (<>
    <TitleWithLine title={intl.formatMessage({id: 'Posts'})}/>
    <div className="post-list-box">
      {list.map(e=><div key={e.id} className='post-list-item'>
        <ImageView
          id={e.imageId}
          screen={IsMobile() ? 'mobile' : 'desktop'}
          onClick={()=>navigate(`/${intl.locale}/post/${e.slug}-${e.id}`)}
        />
        <Link to={`/${intl.locale}/post/${e.slug}-${e.id}`} className='post-title'>{Truncate(e.title, 50)}</Link>
        <p className='post-description'>{Truncate(e.description, 200)}</p>
      </div>)}
    </div>
    {list.length > 0 ? <div className="post-list-box-footer">
      <button
        className='load-more'
        style={{display: `${hasNextPage ? '' : 'none'}`}}
        onClick={_=>setPage(1+page)}
      >
        {intl.formatMessage({id: 'Load more'})}
      </button>
    </div> : null}
  </>
  )
}
export default PostList