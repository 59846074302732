import React from 'react'
import MainMenu from './MainMenu'
import './MainHeader.css'

const MainHeader: React.FC = () => {

  return (
    <>
      <header className=''>
        <MainMenu/>
      </header>
    </>
  )
}

export default MainHeader
