import axios from 'axios'
import * as querystring from 'querystring'
import {UserInitModel} from '../models/UserInitModel'
import {InitialUser, UserModel} from '../../subscribers/models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'api'

const GET_USER_BY_TOKEN_URL = `${API_URL}/private/user/get-by-token`
const LOGIN_URL = `${API_URL}/user/login`
const LOGOUT_URL = `${API_URL}/user/logout`
const INIT_URL = `${API_URL}/user/init`
const VERIFY_URL = `${API_URL}/user/sms-verify`

// Server should return AuthModel
export function login(phone: string, password: string) {
  return axios.post(LOGIN_URL, querystring.stringify({ phone, password }))
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

// register new user
export function userInit(m: UserInitModel) {
  return axios.post(
    INIT_URL,
    querystring.stringify({
      phone: m.phone,
      password: m.password,
      full_name: m.fullName,
      district_id: m.districtId,
      birth: m.birth,
    })
  )
}

export function smsVerify(id: string, code: string) {
  return axios.post(VERIFY_URL, querystring.stringify({ id, sms_code: code }))
}

//

export function getUserByToken(): Promise<UserModel> {
  return axios.post(GET_USER_BY_TOKEN_URL).then((response) => {
    if (response.status === 200) {
      return {
        id: response.data.id,
        modelId: response.data.model_id,
        fullName: response.data.full_name,
        phone: response.data.phone,
        status: response.data.status,
      } as UserModel
    } else {
      return InitialUser
    }
  })
}
