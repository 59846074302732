import React from 'react'
import './MainFooter.css'

const MainFooter: React.FC = () => {
  return (<footer>
    <span className='copyright'>© All right received <a href='www.ofen.uz'>ofen.uz</a></span>
    {/*<div className='app-store-icons'>*/}
    {/*  <img src={'/images/icons/apple-app-store.svg'} alt={'App store'}/>*/}
    {/*  <img src={'/images/icons/google-play.svg'} alt={'Google play'}/>*/}
    {/*</div>*/}
  </footer>)
}

export default MainFooter
