import * as React from 'react'
import {useEffect, useState} from 'react'
import InitFooterJS from './InitFooterJS'
import {ModalContext, useLang} from '../../AppContext'
import Modal from './components/modal/Modal'
import {Outlet, useParams} from 'react-router-dom'
import MainHeader from './components/MainHeader'
import MainFooter from './components/MainFooter'
import './MainLayout.css'
import LoadingSpinner from './components/LoadingSpinner'

const MainLayout: React.FC = () => {
  const {lan} = useParams<{lan: 'uz'|'ru'|'en'}>()
  const {setLang} = useLang()

  const [modalNode, setModalNode] = useState<React.ReactNode>(null)

  // eslint-disable-next-line
  useEffect(() => {
    if (modalNode) {
      document.querySelector('.dialog-modal-overlay')?.addEventListener('click', ({ target }) => {
        if (target && (target as Element).className === 'dialog-modal-overlay') {
          setModalNode(null)
        }
      })
    }
  }, [modalNode])

  // eslint-disable-next-line
  useEffect(()=>setLang(lan ?? 'uz'), [])

  return (
    <>
      <ModalContext.Provider value={{open: false, childNode: null, setModal(value: React.ReactNode) {setModalNode(value)}}}>
        {/* begin::Modal */}
        <Modal>{modalNode}</Modal>
        {/* end::Modal */}
        {/* begin::LoadingSpinner */}
        <LoadingSpinner />
        {/* end::LoadingSpinner */}
        <div className={`${modalNode ? 'overlay-' : ''}page-wrapper`}>
          <MainHeader/>
          {/*Placeholder*/}
          <Outlet/>
          {/*End Placeholder*/}
          <MainFooter/>
        </div>
        <InitFooterJS />
      </ModalContext.Provider>
    </>
  )
}

export default MainLayout
