import { useLocation } from 'react-router-dom'

export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function CheckIsActive(path: string) {
  const current = getCurrentUrl(path)
  const { pathname } = useLocation()
  if (!current || !path) {
    return false
  }

  return pathname.indexOf(current) === 0 && pathname.length === current.length
}
