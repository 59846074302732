import React, {createContext, useContext} from 'react'

// language context
export const InitialLang = 'uz'

type LanguageProps = {
  lang: 'uz' | 'ru' | 'en'
  setLang: React.Dispatch<React.SetStateAction<'uz' | 'ru' | 'en'>>
}
export const LanguageContext = createContext<LanguageProps>({
  lang: InitialLang,
  setLang: (v) => {},
})

// search context
type SearchProps = { search: string }

// modal context
type ModalProps = {
  open: boolean
  childNode: React.ReactNode
  setModal(value: React.ReactNode): void
}
export const ModalContext = createContext<ModalProps>({
  open: false,
  childNode: null,
  setModal: (v) => {},
})

export const useModal = (): ModalProps => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}

export const useLang = (): LanguageProps => {
  const context = useContext(LanguageContext)
  if (!context) {
    throw new Error('useLang must be used within a LangProvider')
  }
  return context
}

export const SearchContext = createContext<SearchProps>({ search: '' })
