import React, {useEffect, useState} from 'react'
import './MainMenu.css'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLang} from '../../../AppContext'
import {CheckIsActive} from '../../modules/global/helpers/RouteHelpers'

const MainMenu: React.FC = () => {
  const intl = useIntl()
  const {lang, setLang} = useLang()

  const [showMenu, setShowMenu] = useState<boolean>(false)

  const checkActive = (path: string) => {
    if (CheckIsActive(path)) {
      return  'active'
    }
    return ''
  }

  // eslint-disable-next-line
  useEffect(()=>{
    const el = document.querySelector<HTMLDivElement>('.page-wrapper')
    if (el) {
      el.addEventListener('click', ()=>setShowMenu(false))
    }
  }, [])

  // eslint-disable-next-line
  useEffect(()=>setShowMenu(false), [lang, ])

  return <nav>
    <Link to={`/${intl.locale}`} className='top-logo' onClick={_=>setShowMenu(false)}>
      <img src={'/images/logo.svg'} alt='ofen.uz'/>
    </Link>
    <ul style={{display: showMenu ? 'block' : ''}}>
      <li className={checkActive('/')}>
        <Link to={`/${intl.locale}`} onClick={_=>setShowMenu(false)}>{intl.formatMessage({id: 'Home'})}</Link>
      </li>
      <li className={checkActive('/posts')}>
        <Link to={`/${intl.locale}/posts`} onClick={_=>setShowMenu(false)}>{intl.formatMessage({id: 'Post'})}</Link>
      </li>
      <li className={checkActive('/categories')}>
        <Link to={`/${intl.locale}/categories`} onClick={_=>setShowMenu(false)}>{intl.formatMessage({id: 'Category'})}</Link>
      </li>
      <li className={checkActive('/project')}>
        <Link to={`/${intl.locale}/project`} onClick={_=>setShowMenu(false)}>{intl.formatMessage({id: 'Project'})}</Link>
      </li>
      <li className='change-language'>
        <button type='button' className={lang === 'uz' ? 'active' : ''} onClick={()=>setLang('uz')}>Uz</button>
        <button type='button' className={lang === 'ru' ? 'active' : ''} onClick={()=>setLang('ru')}>Ru</button>
        <button type='button' className={lang === 'en' ? 'active' : ''} onClick={()=>setLang('en')}>En</button>
      </li>
    </ul>
    <button type='button' className='mobile-menu' onClick={_=>setShowMenu(!showMenu)}>
      <img src={'/images/icons/hamburger-button.svg'} alt={'mobile-menu'} width={50} height={50}/>
    </button>
  </nav>
}
export default MainMenu