import React, {useEffect, useState} from 'react'
import {imageThumb} from '../redux/imageCRUD'
import {toImageUrl} from '../helpers/AssetHelper'

type Props = {
  id?: string
  screen: 'small'|'mobile'|'desktop'
  className?: string
  onClick?(): void
}

type ImgModel = {
  src: string,
  title: string
}

const InitImg: ImgModel = {src: '/images/no-image.svg', title: 'no image'}
const ImageView: React.FC<Props> = ({id, screen, className, onClick}) => {
  const [img, setImg] = useState<ImgModel>(InitImg)
  const size = (screen: 'small'|'mobile'|'desktop'='mobile') => {
    switch(screen) {
      case 'small': return {width: 136, height: 78}
      case 'mobile': return {width: 609, height: 348}
      case 'desktop': return {width: 1350, height: 772}
    }
  }

  const loadData = () => {
    if (id && id.length === 36) {
      imageThumb(id, screen).then(r=>{
        if (r.status === 200 && r.data.path.length > 36) {
          setImg({src: toImageUrl(r.data.path), title: r.data.title})
        }
      })
    }
  }

  // eslint-disable-next-line
  useEffect(()=>loadData(), [id, screen])

  return <img
    className={className}
    src={img.src}
    alt={img.title}
    width={size(screen).width}
    height={size(screen).height}
    onClick={onClick}
  />
}
export default ImageView