export const messages = {
  uz: {
    Home: 'Bosh sahifa',
    Menu: 'Menu',
    Search: 'Izlash',
    'No photo': "Foto yo'q",
    Top: "Ko'p ko'rilgan",
    Recommended: 'Tavsiya etamiz',
    Uz: 'Uz',
    Ru: 'Ru',
    En: 'En',
    'Top posts': "Mashxur maqolalar",
    "Similar posts": "O'xshash maqolalar",
    "Load more": "Yana ko'rish",
    "Recommended posts": "Tavsiya etiladigan maqolalar",
    Category: "Kategoriya",
    Categories: "Kategoriyalar",
    Post: "Maqola",
    Posts: "Maqolalar",
    Project: "Loyixa",
    Loading: "Yuklanmoqda",
  },
  ru: {
    Home: 'Главная',
    Menu: 'Меню',
    Search: 'Поиск',
    'No photo': "Нет фото",
    Top: "Топ просмотров",
    Recommended: 'Рекомендуем',
    Uz: 'Uz',
    Ru: 'Ru',
    En: 'En',
    'Top posts': "Популярные посты",
    "Similar posts": "Похожие посты",
    "Load more": "Посмотреть еще",
    "Recommended posts": "Рекомендуемые посты",
    Category: "Категория",
    Categories: "Категории",
    Post: "Пост",
    Posts: "Посты",
    Project: "Проект",
    Loading: "Загрузка",
  },
  en: {
    Home: 'Home',
    Menu: 'Menu',
    Search: 'Search',
    'No photo': "No photo",
    Top: "Top",
    Recommended: 'Recommended',
    Uz: 'Uz',
    Ru: 'Ru',
    En: 'En',
    'Top posts': "Top posts",
    "Similar posts": "Similar posts",
    "Load more": "Load more",
    "Recommended posts": "Recommended posts",
    Category: "Category",
    Categories: "Categories",
    Post: "Post",
    Posts: "Posts",
    Project: "Project",
    Loading: "Loading",
  },
}
