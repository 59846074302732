import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import TitleWithLine from '../../global/components/TitleWithLine'
import {useNavigate} from 'react-router-dom'
import {Truncate} from '../../global/helpers/StringHelper'
import './Categories.css'
import {categoryList} from '../redux/categoryCRUD'
import {NumberFormat} from '../../global/helpers/ToHumansString'
import {usePageData} from '../../../layouts/core/PageData'

type CategoryModel = {
  id: string
  title: string
  slug: string
  postCount: number
}

const Categories: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const{setPageTitle} = usePageData()

  const [list, setList] = useState<CategoryModel[]>([])
  const [hasNextPage, setHasNextPage] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)

  const loadData = () => {
    setPageTitle(intl.formatMessage({id: 'Categories'}))
    categoryList({lan: intl.locale, perPage: 8, page}).then(r=>{
      if (r.status === 200) {
        const items = (r.data.items ?? []).map((e: any)=>{
          return {
            id: e.id,
            title: e.title ?? '',
            slug: e.slug ?? '',
            postCount: e.post_count,
          }
        })
        const page = Number(r.data.paginate.page)
        setList([...(page > 1 ? list : []), ...items])
        setPage(page)
        setHasNextPage(page < Number(r.data.paginate.total_page))
      }
    })
  }

  // eslint-disable-next-line
  useEffect(()=> loadData(), [page, intl.locale])

  return (<>
    <TitleWithLine title={intl.formatMessage({id: 'Categories'})}/>
    <div className="categories-box">
      {list.map(e=><div key={e.id}
        className='categories-item'
        onClick={_=>navigate(`/${intl.locale}/posts/${e.slug}-${e.id}`)}>
        <div className="categories-item-main">
          <div className="categories-item-main-image">
            <img src={'/images/icons/category.svg'} alt={e.title}/>
          </div>

          <span className='categories-item-main-title'>{Truncate(e.title, 50)}</span>
        </div>
        <div className="categories-item-info">
          <span className='categories-post-count'>
            {intl.formatMessage({id: 'Posts'})}: {NumberFormat(e.postCount)}
          </span>
        </div>
      </div>)}
    </div>
    {list.length > 0 ? <div className="categories-box-footer">
        <button
          className='load-more'
          style={{display: `${hasNextPage ? '' : 'none'}`}}
          onClick={_=>setPage(1+page)}
        >
          {intl.formatMessage({id: 'Load more'})}
        </button>
      </div> : null}
    </>
  )
}
export default Categories