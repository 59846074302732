import {IDataFilter} from "../../global/models/DataFilter";
import axios from "axios";
import * as querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'category'
const LIST = `${API_URL}/${_RESOURCE}/list`

export function categoryList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
    per: filter.perPage,
    lan: filter.lan,
  }))
}

