import { FC, useRef, useEffect, useState, PropsWithChildren } from 'react'
import {
  shallowEqual,
  useSelector,
  connect,
  useDispatch,
  ConnectedProps,
} from 'react-redux'
import * as auth from './AuthRedux'
import { getUserByToken } from './AuthCRUD'
import { RootState } from '../../../../setup/redux/RootReducer'

const mapState = (state: RootState) => ({ auth: state.auth })
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsWithChildren<PropsFromRedux>> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const token = useSelector<RootState>(({ auth }) => auth.token, shallowEqual)

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const user = await getUserByToken()
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (token) {
      requestUser().then()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <>404</> : <>{props.children}</>
}

export default connector(AuthInit)
