import axios, {AxiosResponse} from "axios";
import * as querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'page'
const GET = `${API_URL}/${_RESOURCE}/get`

export function pageGet(code: string, lan: string) {
  if (code && code.length > 0) {
    return axios.post(GET, querystring.stringify({code, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

