import axios from "axios";
import * as querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'post'
const LIKE = `${API_URL}/${_RESOURCE}/like`

export function postLike(id: string) {
  return axios.post(LIKE, querystring.stringify({id}))
}