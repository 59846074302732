import {IDataFilter} from "../../global/models/DataFilter";
import axios, {AxiosResponse} from "axios";
import * as querystring from "querystring";

const API_URL = process.env.REACT_APP_API_URL

const _RESOURCE = 'post'
const LIST = `${API_URL}/${_RESOURCE}/list`
const TOP_LIST = `${API_URL}/${_RESOURCE}/top-list`
const SIMILAR_LIST = `${API_URL}/${_RESOURCE}/similar-list`
const RECOMMENDED_LIST = `${API_URL}/${_RESOURCE}/recommended-list`
const GET = `${API_URL}/${_RESOURCE}/get`

export function postList(filter: IDataFilter) {
  return axios.post(LIST, querystring.stringify({
    search: filter.search,
    page: filter.page,
    per: filter.perPage,
    lan: filter.lan,
    category_ids: filter.categories ? JSON.stringify(filter.categories) : null
  }))
}

export function postTopList(lan: string) {
  return axios.post(TOP_LIST, querystring.stringify({ lan }))
}

export function postSimilarList(lan: string, ids: string[]) {
  return axios.post(SIMILAR_LIST, querystring.stringify({
    lan,
    ids: JSON.stringify(ids)
  }))
}

export function postRecommendedList(lan: string, id?: string) {
  return axios.post(RECOMMENDED_LIST, querystring.stringify({ lan, id}))
}

export function postGet(id: string, lan: string) {
  if (id && id.length === 36) {
    return axios.post(GET, querystring.stringify({id, lan}))
  } else {
    return new Promise<AxiosResponse<any>>(_ => {})
  }
}

