import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom"
import MainLayout from "../layouts/MainLayout"
import HomePage from '../pages/HomePage'
import PostViewPage from '../modules/posts/PostViewPage'
import PostsPage from '../modules/posts/PostsPage'
import CategoriesPage from '../modules/posts/CategoriesPage'
import PageView from '../pages/PageView'

const MainRoutes: React.FC = () => {
  return <BrowserRouter>
    <Routes>
      {/* begin::MainRoutes */}
      <Route path={'/:lan?'} element={<MainLayout />}>
        <Route index element={<HomePage/>}/>
        <Route path={'posts/:tid?'} element={<PostsPage/>}/>
        <Route path={'categories'} element={<CategoriesPage/>}/>
        <Route path={'project'} element={<PageView code={'project'}/>}/>
        <Route path={'post/:tid'} element={<PostViewPage />}/>
      </Route>
      {/* end::MainRoutes */}
    </Routes>
  </BrowserRouter>
}

export default MainRoutes