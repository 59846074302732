import React, {useEffect} from 'react'
import PostList from '../modules/posts/components/PostList'
import RecPostList from '../modules/posts/components/RecPostList'
import {usePageData} from '../layouts/core/PageData'
import {useIntl} from 'react-intl'

const HomePage: React.FC = () => {
  const intl = useIntl()
  const{setPageTitle} = usePageData()

  // eslint-disable-next-line
  useEffect(()=>setPageTitle(intl.formatMessage({id: 'Home'})), [intl.locale])

  return <>
    <RecPostList />
    <PostList/>
  </>
}
export default HomePage